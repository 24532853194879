import React, { useContext, useEffect, useState } from "react";

import { QueryClient, useInfiniteQuery, useQuery } from "react-query";
import { isEqual } from "lodash";

import { useGlobalContext } from "context/AppContext";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";

import { ProfileModel } from "@models/ProfileModels";
import { CMSContextContent } from "@models/cms/fileds";
import { GroupMemberTypeInList, GroupTypeInList } from "@models/group/GroupModel";

import { getGroups, joinGroup } from "@services/groupsProvider";
import useScrollToBottom from "./useScrollToBottom";
import { useSearchParams } from "react-router-dom";
import { checkLoggedIn, clearFalsyFromObject, clearFalsyFromObject2, isEmpty, paramsToObject } from "utils/functions";

export type GroupFilters = {
    sort?: string;
    location?: string;
    game?: string[];
    platform?: string[];
    country?: string;
    state?: string;
    city?: string;
    safetyTools?: string;
    languages?: string[];
    game_hosting_type?: string;
};

type GroupFiltersQuery = {
    sort?: string;
    location?: string;
    game?: string;
    platform?: string;
    country?: string;
    state?: string;
    city?: string;
    safetyTools?: string;
    languages?: string;
    game_hosting_type?: string;
};

export const defaultFilters: GroupFilters = {
    sort: "",
    location: "",
    country: "",
    state: "",
    city: "",
    safetyTools: "",
    game_hosting_type: "",
};

export const typesOfFilter = Object.keys(defaultFilters);
export const FilterContext = React.createContext(defaultFilters);

export type GlobalContextType = {
    personalProfile: ProfileModel | undefined;
    isLoggedIn: boolean;
    isLoggedInVerified: boolean;
};

export default function useGroupsList(ref: React.RefObject<HTMLDivElement>) {
    /* States and hooks */
    const [pageNumber, setPageNumber] = useState(1);
    const [pageTransition, setPageTransition] = useState(true);
    const [hasFilter, setHasFilter] = useState(false);
    const [filters, setFilters] = useState(defaultFilters);
    const [currentFilters, setCurrentFilters] = useState<GroupFilters>({});
    const [searchParams, setSearchParams] = useSearchParams();

    const { personalProfile, isLoggedIn }: GlobalContextType = useGlobalContext();
    const { loading: loadingCMS }: CMSContextContent = useContext(AsyncFilterFieldsContext);

    const [groups, setGroups] = useState<GroupTypeInList[]>([]);

    /* Functions */
    const getWorkingFilters = () => {
        const currentParams = paramsToObject(searchParams) as GroupFilters;
        const workingFilters = clearFalsyFromObject2(currentParams) as GroupFilters;

        if (workingFilters?.game) {
            const splitGames = workingFilters?.game?.toString().split(",");
            workingFilters.game = splitGames;
        }

        if (workingFilters?.platform) {
            const splitGamePlatform = workingFilters?.platform?.toString().split(",");
            workingFilters.platform = splitGamePlatform;
        }

        if (workingFilters?.languages) {
            const splitLanguages = workingFilters?.languages?.toString().split(",");
            workingFilters.languages = splitLanguages;
        }

        if (Object.keys(workingFilters).length === 0) {
            const hasCurrentFilters = JSON.stringify(currentParams) !== JSON.stringify(defaultFilters);
            if (hasCurrentFilters) {
                return { ...currentParams };
            }
        }

        return workingFilters;
    };

    const generateQueryParam = (data: any, setParameterInUrl = true) => {
        const entries = searchParams.entries();
        const current: { [key: string]: string } = paramsToObject(entries) as { [key: string]: string };

        let params = new URLSearchParams(data);

        if (entries && !isEqual(data, current)) {
            if (data.country || data.location) {
                ["country", "state", "city", "location"].forEach((x) => delete current[x]);
            }
            params = new URLSearchParams(clearFalsyFromObject({ ...current, ...data }));
        }

        if (setParameterInUrl) {
            setSearchParams(params);
        }
        return params;
    };

    const removeQueryParam = (key: string | string[]) => {
        const entries = searchParams.entries();
        const params = new URLSearchParams(paramsToObject(entries) as {});
        if (Array.isArray(key)) {
            key.forEach((k) => params.delete(k));
        } else {
            params.delete(key);
        }
        setSearchParams(params);
    };

    const setDefaultFilters = () => {
        setPageNumber(1);
        setFilters({ ...defaultFilters });
        setCurrentFilters({ ...defaultFilters });
        setSearchParams();
        setHasFilter(false);
    };

    const handleFilters = (type: any, value: any) => {
        let params, newFilters;
        switch (type) {
            case "safetyTools":
                if (filters.safetyTools === value) {
                    newFilters = { ...filters, safetyTools: "" };
                    removeQueryParam(type);
                } else {
                    newFilters = { ...filters, safetyTools: value };
                    params = generateQueryParam({ [type]: value });
                }
                setFilters(newFilters);
                break;
            case "platform":
                const platformQuerystring = value.map((item: any) => item.id);
                newFilters = { ...filters, [type]: platformQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: platformQuerystring.join(",") });
                break;
            case "game":
                const gameQuerystring = value.map((item: any) => item.id);
                newFilters = { ...filters, [type]: gameQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: gameQuerystring.join(",") });
                break;
            case "location":
                newFilters = {
                    ...filters,
                    country: value?.country || "",
                    state: value?.state || "",
                    city: value?.city || "",
                    location: value?.location || "",
                };

                if (value === null) {
                    removeQueryParam(["country", "state", "city", "location"]);
                } else {
                    params = generateQueryParam(value);
                }

                setFilters(newFilters);
                break;
            case "languages":
                const languageQuerystring = value.map((item: any) => item.id);
                newFilters = { ...filters, [type]: languageQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: languageQuerystring.join(",") });
                break;
            case "sort":
                newFilters = { ...filters, [type]: value };
                setFilters(newFilters);
                break;
            case "game_hosting_type":
                newFilters = { ...filters, [type]: value };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: value });
                break;
            default:
                newFilters = { ...filters };
                setFilters(newFilters);
                break;
        }

        return { params, newFilters };
    };

    const applyFilter = (filters: GroupFilters, goBackFilters: boolean = false) => {
        setPageNumber(1);
        setHasFilter(true);
        setGroups([]);
        setCurrentFilters(filters);
        if (Object.keys(clearFalsyFromObject2(filters) as object).length <= 0) {
            refetch();
        }
    };

    const onBackFilter = () => {
        applyFilter({}, true);
    };

    const handleJoinGroup = async (group: GroupTypeInList) => {
        // Check if the group is full or if the profile is completed
        const groupIsFull = group.group_size <= (group.members?.length || 0);

        if (!personalProfile?.name) throw new Error("Personal Profile not completed");
        if (groupIsFull) throw new Error("This group is already full");

        // Join the group
        await joinGroup(group.id);

        // Create the new member being yourself
        const member: GroupMemberTypeInList = {
            id: personalProfile.id as string,
            name: personalProfile?.name?.[0],
            gender: personalProfile?.gender?.[0] || "",
            avatar_id: personalProfile?.avatar_id || 0,
            profileURL: personalProfile.profileURL?.[0] || "",
            vanityUrl: personalProfile?.vanityUrl?.[0] || "",
            matchScore: 100,
            member_type: "player",
        };

        // Add the new member to the group state
        setGroups(
            groups.map((_group: GroupTypeInList): GroupTypeInList => {
                if (_group.id !== group.id) return _group;
                else {
                    _group.members?.push(member);
                    return _group;
                }
            }),
        );
    };

    const clearFilter = async ({ preserveFilters, removeFilters } = { preserveFilters: [], removeFilters: [] }) => {
        if (preserveFilters?.length > 0 && removeFilters?.length > 0) {
            console.error("You can not use removeFilters and preserveFilters at the same time");
            return;
        }

        setGroups([]);

        let copyOfFilters = global.structuredClone(currentFilters);
        let newFilters = global.structuredClone(defaultFilters);

        if (removeFilters?.length > 0) {
            removeFilters.forEach((filterKey: string | { key: string; value: string }) => {
                try {
                    // Checking if the filter is an object;
                    const isObject = typeof filterKey === "object";
                    const key = isObject ? filterKey.key : filterKey;
                    const defaultFilterKey = newFilters[key as keyof GroupFilters];
                    let shouldDeleteParam = false;
                    // If object, we should check for the param to remove and keep the others if exists
                    if (isObject) {
                        const currentParams = paramsToObject(searchParams) as GroupFilters;
                        const values = currentParams[key as keyof GroupFilters] as string;
                        const splittedValues = values?.split(",");
                        // If there's more than one choosen option, we should remove and keep the others
                        if (splittedValues.length > 1) {
                            const valuesToKeep = splittedValues.filter((value) => value !== filterKey.value.toString());
                            const entries = searchParams.entries();
                            const params = new URLSearchParams(paramsToObject(entries) as {});
                            params.set(key, valuesToKeep.join(","));
                            setSearchParams(params);
                            copyOfFilters = { ...copyOfFilters, [key]: valuesToKeep };
                        }
                        // If there's only one choosen option, we should remove all the param
                        else {
                            shouldDeleteParam = true;
                        }
                    }
                    // If not an object, we should remove all the param
                    else {
                        shouldDeleteParam = true;
                    }

                    if (shouldDeleteParam) {
                        delete copyOfFilters[key as keyof GroupFilters];
                        removeQueryParam(key);
                        copyOfFilters = { ...copyOfFilters, [key]: defaultFilterKey };
                    }
                    newFilters = { ...newFilters, ...copyOfFilters };
                } catch (error) {
                    console.log("the filter had an error");
                }
            });
        }

        setFilters(newFilters);
        setCurrentFilters(copyOfFilters);
        if (isEmpty(copyOfFilters)) {
            setHasFilter(false);
        }
    };

    const fetchGroups = async (parsedFilters: GroupFiltersQuery, pageNumber: number) => {
        const groups = await getGroups({ pageNumber, ...parsedFilters });
        return groups;
    };

    /* Lifecycle */
    const { isLoading, fetchNextPage, refetch, isFetched } = useInfiniteQuery({
        /* Settings */
        retry: 1,
        enabled: !loadingCMS,
        cacheTime: 5,
        keepPreviousData: false,
        queryKey: ["GroupsList", currentFilters, pageNumber],
        /* Query */
        queryFn: ({ queryKey, pageParam = 1 }) => {
            const parsedQuery: GroupFilters | undefined = clearFalsyFromObject(queryKey[1]);

            const filter = { ...parsedQuery } as GroupFiltersQuery;
            if (parsedQuery?.platform && parsedQuery.platform.length > 0) {
                filter.platform = parsedQuery?.platform?.join(",") || "";
            }

            if (parsedQuery?.game && parsedQuery.game.length > 0) {
                filter.game = parsedQuery?.game?.join(",") || "";
            }

            if (parsedQuery?.languages && parsedQuery.languages.length > 0) {
                filter.languages = parsedQuery?.languages?.join(",") || "";
            }

            return fetchGroups(filter, pageParam);
        },
        /* After query */
        onSuccess: (data) => {
            setGroups(data.pages.flat());
        },
        onError(err) {
            setPageTransition(false);
        },

        /* Pagination */
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.length === 0) return undefined; // No more pages to fetch
            return allPages.length + 1; // This should correctly set the next page number
        },
    });

    useScrollToBottom(ref, () => {
        setPageTransition(true);
        fetchNextPage();
    });

    // Owned groups
    const { data: ownedGroups } = useQuery({
        enabled: Boolean(isLoggedIn),
        queryKey: ["OwnedGroups"],
        queryFn: async () => getGroups({ QueryType: "OWNED" }),
    });

    //Joined groups
    const { data: joinedGroups } = useQuery({
        enabled: Boolean(isLoggedIn),
        queryKey: "JoinedGroups",
        queryFn: async () => getGroups({ QueryType: "JOINED" }),
    });

    const queryClient = new QueryClient();

    /* In case the user logs in or out while it is in the page */
    useEffect(() => {
        // Reset to first page
        setPageNumber(1);
        // Clear the groups state
        setGroups([]);
        // reload all groups
        queryClient.resetQueries(["GroupsList", "JoinedGroups", "OwnedGroups"]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        (async () => {
            const checkIfLogged = await checkLoggedIn();
            if ((!checkIfLogged && !personalProfile) || personalProfile) {
                const workingFilters = getWorkingFilters();
                const hasFilters = Object.keys(workingFilters).length > 0;

                if (hasFilters) {
                    applyFilter(workingFilters);
                    setHasFilter(true);
                }

                if (personalProfile) {
                    if (!hasFilters) {
                        applyFilter({
                            country: (personalProfile && personalProfile.country && personalProfile.country[0]) ?? "",
                        });
                    }
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalProfile]);

    /* Variables */

    return {
        /* Lists */
        groups,
        ownedGroups: ownedGroups || [],
        joinedGroups: joinedGroups || [],
        discoverGroups: groups || [],
        /* Filters */
        filters,
        applyFilter,
        clearFilter,
        handleFilters,
        hasFilter,
        setDefaultFilters,
        currentFilters,
        onBackFilter,
        generateQueryParam,
        /* State */
        isLoading,
        isFetched,
        pageTransition,
        /* Functions */
        handleJoinGroup,
        /* Variables */
        userOwnsGroup: Boolean(ownedGroups?.length),
    };
}
