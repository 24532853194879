import "./GroupsFilter.scss";

import React, { forwardRef, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GroupFilterPopup from "./components/GroupFilterPopup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box } from "@mui/material";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

type GroupsFilterProps = React.PropsWithChildren & {
    filters: any;
    filterFields: any;
    applyFilter: (filters: any, goBackFilters?: boolean) => void;
    handleFilters: any;
    setDefaultFilters: () => void;
    hasFilter: boolean;
    isLoggedIn: boolean;
    setLoginPopup: (open: boolean) => void;
    userOwnsGroup?: boolean;
};
const GroupsFilter = forwardRef(
    (
        {
            filters,
            filterFields,
            applyFilter,
            handleFilters,
            setDefaultFilters,
            hasFilter,
            isLoggedIn,
            setLoginPopup,
            children,
            userOwnsGroup = false,
        }: GroupsFilterProps,
        ref,
    ) => {
        const sortingOptions = ["Group creation Date", "Match score"];
        const navigate = useNavigate();

        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        const handleCloseFilter = () => {
            setAnchorEl(null);
        };

        const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
            if (isLoggedIn) {
                setAnchorEl(event.currentTarget);
            } else {
                setLoginPopup(true);
            }
        };

        const handleClearFilter = (event: any, clearOptions: any) => {
            event.stopPropagation();
            event.preventDefault();
            if (isLoggedIn) {
                setDefaultFilters();
            } else {
                setLoginPopup(true);
            }
        };

        return (
            <Box className="groups-filter-wrapper">
                <Box className="groups-filter-inner-wrapper">
                    <Box className="flex justify-between items-center groups-filter-header">
                        <Box className="flex gap-2 items-center ">
                            <img style={{ width: "25px", height: "25px" }} alt="People" src={"/ListItemIcon.png"} />
                            <Typography variant="h1">Groups</Typography>
                        </Box>
                        <Box>
                            <Button
                                className={`user-list-header-btn ${anchorEl ? "active" : ""} ${
                                    hasFilter ? "selected" : "not-selected"
                                }`}
                                variant="outlined"
                                startIcon={<FilterListIcon />}
                                endIcon={
                                    hasFilter && (
                                        <ClearIcon
                                            data-testid="clear-button"
                                            onClick={(event) => handleClearFilter(event, { preserveFilters: ["sort"] })}
                                        />
                                    )
                                }
                                onClick={handleOpenFilter}>
                                FILTERS & SORT
                            </Button>
                        </Box>
                    </Box>

                    <ShouldRender returnNull condition={Boolean(isLoggedIn) && !userOwnsGroup}>
                        <button
                            onClick={() => navigate("/create-group")}
                            className={"create-your-group-button my-3 mx-2"}>
                            <AddCircleIcon sx={{ color: "#9E9E9E", fontSize: "44px" }} />
                            <Box>
                                <p>Create your group</p>
                                <p>
                                    <small>
                                        Only one group allowed per
                                        <br /> Game Master
                                    </small>
                                </p>
                            </Box>
                        </button>
                    </ShouldRender>

                    <Box ref={ref} className="groups-filter-content">
                        {children}
                    </Box>
                </Box>

                <GroupFilterPopup
                    showFilter={Boolean(anchorEl)}
                    filters={filters}
                    filterFields={filterFields}
                    handleFilters={handleFilters}
                    applyFilter={applyFilter}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    handleClearFilter={handleClearFilter}
                    handleCloseFilter={handleCloseFilter}
                    sortingOptions={sortingOptions}
                />
            </Box>
        );
    },
);

export default GroupsFilter;
