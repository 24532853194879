const keys = {
    redirect: "redirect",
    surveyRedirect: "surveyRedirect",
    inheiritedGroupState: "inheiritedGroupState",
    invitedUsers: "InvitedUsers",
    messageID: "MessageID",
};

type RedirectObject = {
    // The current key stored in browser session storage
    key: string;
    // Removes the value imediately
    remove: () => void;
    // Updates the value imediately
    update: (path: string) => void;
    // Returns the current value
    get: () => string | null;
    // Returns the current value and in case the page
    // was successfully redirected then removes the value
    use: () => string | null;
    // Automatically gets the current path and search params
    // and sets it in session storage
    setPath: () => string;
};

function getAndRemove(key: RedirectObject["key"], remove: RedirectObject["remove"]): string | null {
    const path = sessionStorage.getItem(key);

    // Get the current path so we can check the page was redirected
    const initial = window.location.pathname;

    if (path)
        setTimeout(() => {
            const final = window.location.pathname;
            if (
                // If the page was redirected
                initial !== final &&
                // And the path is correct
                final.includes(path)
            )
                //  Then remove the redirect
                remove();
        }, 1000);

    return path;
}

function automaticallySet(update: RedirectObject["update"]): string {
    // Mount the current pathname with search, it will be like: /groups?sort=matchscore
    const currentPath = `${window.location.pathname}${window.location.search}`;
    // Execute the update function inside the redirect object
    update(currentPath);
    // Return the current path
    return currentPath;
}

export const redirect: RedirectObject = {
    key: keys.redirect,
    remove: () => sessionStorage.removeItem(keys.redirect),
    update: (path: string) => sessionStorage.setItem(keys.redirect, path),
    get: () => sessionStorage.getItem(keys.redirect),
    use: function () {
        return getAndRemove(this.key, this.remove);
    },
    setPath: function () {
        return automaticallySet(this.update);
    },
};
export const surveyRedirect: RedirectObject = {
    key: keys.surveyRedirect,
    remove: () => sessionStorage.removeItem(keys.surveyRedirect),
    update: (path: string) => sessionStorage.setItem(keys.surveyRedirect, path),
    get: () => sessionStorage.getItem(keys.surveyRedirect),
    use: function () {
        return getAndRemove(this.key, this.remove);
    },
    setPath: function () {
        return automaticallySet(this.update);
    },
};

const invitedUsers = {
    key: keys.invitedUsers,
    get: (): Array<string> => JSON.parse(sessionStorage.getItem(keys.invitedUsers) || "[]"),
    set: (users: Array<string>) => sessionStorage.setItem(keys.invitedUsers, JSON.stringify(users)),
    delete: () => sessionStorage.removeItem(keys.invitedUsers),
    add: function (id: string) {
        const current = this.get();
        if (current.find((x) => x === id)) return this;
        current.push(id);
        this.set(current);
        return this;
    },
    remove: function (id: string) {
        const current = this.get();
        const found = current.findIndex((x) => x === id);
        if (found === -1) return this;
        current.splice(found, 1);
        this.set(current);
        return this;
    },
};

const messageID = {
    key: keys.messageID,
    get: function () {
        return sessionStorage.getItem(this.key);
    },
    set: function (id: string) {
        sessionStorage.setItem(this.key, id);
        return this;
    },
};

export const storage = {
    sessionStorage: {
        redirect,
        surveyRedirect,
        invitedUsers,
        messageID,
    },
};
