import "./GroupsPage.scss";

import React, { useContext, useRef, useState } from "react";

import { Box } from "@mui/material";

import Groups from "@components/Groups/index.tsx";
import GroupsFilter from "@components/GroupsFilter";
import { AlertPopUpSmall, usePopupController } from "@components/AlertPopUp";
import { LoginContext } from "context/LoginContext";
import useGroupsList, { GlobalContextType } from "@hooks/useGroupsList";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { GroupTypeInList } from "models/group/GroupModel";
import { useGlobalContext } from "context/AppContext";
import { CannotJoinGroupDialog, JoinGroupDialog, JoinGroupWarningDialog } from "@components/Groups/GroupDialogs";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { CMSContextContent } from "models/cms/fileds";
import { platformIsATable } from "utils/functions";
import { storage } from "@services/sessionStorage";
import { LoginBanner } from "components/LoginBanner/LoginBanner";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

export default function GroupsPage() {
    /* States and Hooks */
    const page = useRef(null);

    const [selectedGroup, setSelectedGroup] = useState<GroupTypeInList | null>(null);
    const [joinGroupDialog, setJoinGroupDialog] = useState(false);
    const [joinGroupWarningDialog, setJoinGroupWarningDialog] = useState(false);
    const [loginDialog, setLoginDialog] = useState(false);

    const { isTablet, isDesktop, isLaptop, isMobile } = useBreakpoint();

    const { popup, showPopup } = usePopupController();
    const {
        filters,
        currentFilters,
        applyFilter,
        handleFilters,
        hasFilter,
        setDefaultFilters,
        clearFilter,

        groups,
        ownedGroups,
        joinedGroups,
        handleJoinGroup,
        discoverGroups,
        isLoading,
        isFetched,

        userOwnsGroup,
    } = useGroupsList(page);

    const { setLoginPopup } = useContext(LoginContext);
    const { personalProfile, isLoggedIn, isLoggedInVerified }: GlobalContextType = useGlobalContext();
    const { filterFields, loading: loadingCMS }: CMSContextContent = useContext(AsyncFilterFieldsContext);

    /* Variables */
    const containerClasses: string[] = [
        "group-page",
        "group-list-page",
        "fullscreen-mobile-group-list",
        "group-view-page",
        isMobile ? "mobile-group-page" : "",
        isTablet || isLaptop ? "tablet-group-page" : "",
        isDesktop ? "desktop-group-page" : "",
    ];

    async function openJoinGroupModal(group: GroupTypeInList): Promise<void> {
        try {
            await handleJoinGroup(group); // Join Group

            setJoinGroupDialog(true); // Show success dialog

            // Show success popup
            showPopup({ title: "Success", severity: "success", description: "Group has been successfully joined." });
        } catch (error) {
            // In case of error show error popup
            showPopup({ title: "Error", severity: "error", description: (error as Error).message });
        } finally {
            setJoinGroupWarningDialog(false); // Hide warning dialog
        }
    }

    async function openJoinGroupWarningModal(group: GroupTypeInList): Promise<void> {
        if (!Boolean(isLoggedIn)) {
            setLoginDialog(true);
            return;
        }

        setSelectedGroup(group);
        const platform = filterFields.vtt.find((vtt) => vtt.id === group.game_platform);

        if (!platform?.name) return;

        /* In case the location is not the same then open the warning */
        if (platformIsATable(platform)) {
            if (
                group.city !== personalProfile?.city?.[0] ||
                group.state !== personalProfile?.state?.[0] ||
                group.country !== personalProfile?.country?.[0]
            ) {
                setJoinGroupDialog(false);
                setJoinGroupWarningDialog(true);
                return;
            }
        }

        return openJoinGroupModal(group);
    }

    function handleLogin() {
        storage.sessionStorage.redirect.update("/groups");
        setLoginDialog(false);
        setLoginPopup(true);
    }

    return (
        <React.Fragment>
            {/* Popups */}
            <AlertPopUpSmall {...popup} />
            {/* Dialogs */}
            <CannotJoinGroupDialog open={loginDialog} onConfirm={handleLogin} onUndo={() => setLoginDialog(false)} />
            <JoinGroupWarningDialog
                open={joinGroupWarningDialog}
                onUndo={() => setJoinGroupWarningDialog(false)}
                onConfirm={async () => {
                    if (!selectedGroup) return;
                    await openJoinGroupModal(selectedGroup);
                }}
            />
            <JoinGroupDialog
                open={joinGroupDialog}
                onUndo={() => setJoinGroupDialog(false)}
                groupId={selectedGroup?.id || ""}
                creatorId={selectedGroup?.creator_id}
                onConfirm={() => setJoinGroupDialog(false)}
                onClose={() => setJoinGroupDialog(false)}
            />
            <ShouldRender returnNull condition={!Boolean(isLoggedIn) && isLoggedInVerified}>
                <LoginBanner />
            </ShouldRender>
            {/* Component */}
            <Box className={containerClasses.join(" ")}>
                <GroupsFilter
                    ref={page}
                    userOwnsGroup={userOwnsGroup}
                    isLoggedIn={isLoggedIn}
                    filters={filters}
                    filterFields={filterFields}
                    handleFilters={handleFilters}
                    setDefaultFilters={setDefaultFilters}
                    hasFilter={hasFilter}
                    setLoginPopup={(value: boolean) => {
                        storage.sessionStorage.redirect.update("/groups");
                        setLoginPopup(value);
                    }}
                    applyFilter={applyFilter}>
                    <React.Fragment>
                        <Groups
                            groups={groups}
                            showPopup={showPopup}
                            ownedGroups={ownedGroups}
                            joinedGroups={joinedGroups}
                            discoverGroups={discoverGroups}
                            handleJoinGroup={openJoinGroupWarningModal}
                            isLoading={isLoading}
                            isFetched={isFetched}
                            loadingCMS={loadingCMS}
                            setDefaultFilters={setDefaultFilters}
                            currentFilters={currentFilters}
                            applyFilter={applyFilter}
                            handleFilters={handleFilters}
                            hasFilter={hasFilter}
                            setLoginPopup={setLoginPopup}
                            isLoggedIn={isLoggedIn}
                            clearFilter={clearFilter}
                        />
                    </React.Fragment>
                </GroupsFilter>
            </Box>
        </React.Fragment>
    );
}
