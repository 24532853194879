import { Auth } from "aws-amplify";
import { checkPlayerType } from "utils/checkPlayerType";
import { uniqBy } from "lodash";
import { isBoth, isOnlyGameMaster, isOnlyPlayer, isProfessionalGameMaster } from "utils/isGameMaster";
import { typesOfFilter } from "context/FilterContext";
import { getUserCurrentTimeInfo } from "./userCurrentTime";
import { AVATAR_REMOVED_PLACEHOLDER } from "utils/constants";
import { api } from "services/api";
import { endpoints } from "services/endpoints";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { storage } from "@services/sessionStorage";

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Capitalize the first letter of each word in a string
 * @param {string} str - The string to be capitalized
 * @returns {string} The capitalized string
 */
export function titleCase(str) {
    return str.replaceAll("-", " ");
}

export const signOut = async () => {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log("error signing out: ", error);
    }
};

export const checkLoggedIn = async () => {
    try {
        await Auth.currentAuthenticatedUser();

        return true;
    } catch {
        return false;
    }
};

// Transforms a "No Response" string to blank string
export const validateText = (text) => {
    if (!text || text === "No Response") {
        return "";
    }
    return text;
};

// Uses window href to return a string value indicating environment.
export const checkEnvironment = () => {
    if (window?.location?.href.includes("dev") || window?.location?.href?.includes("local")) {
        let devEnv = window?.location?.href.includes("dev2") ? "development2" : "development";
        return devEnv;
    } else if (window?.location?.href?.includes("stg")) {
        return "staging";
    } else {
        return "production";
    }
};

export const environmentApiUrl = () => {
    switch (checkEnvironment()) {
        case "development":
            return "https://api-dev.rpgmatch.org";
        case "development2":
            return "https://api-dev2.rpgmatch.org";
        case "staging":
            return "https://api-stg.rpgmatch.org";
        case "production":
            return "https://api-prod.rpgmatch.org";
        default:
            return "https://api-prod.rpgmatch.org";
    }
};

export const awsFileURL = () => {
    switch (checkEnvironment()) {
        case "development":
            return "https://development-rpgmatch-profile-images.s3.us-west-2.amazonaws.com";
        case "development2":
            return "https://development2-rpgmatch-profile-images.s3.us-west-2.amazonaws.com";
        case "staging":
            return "https://staging-rpgmatch-profile-images.s3.us-west-2.amazonaws.com";
        case "production":
            return "https://production-rpgmatch-profile-images.s3.us-west-2.amazonaws.com";
        default:
            return "https://production-rpgmatch-profile-images.s3.us-west-2.amazonaws.com";
    }
};

export const contentfulEnvs = () => {
    switch (checkEnvironment()) {
        case "development":
            return {
                space: "0aw1jhk0o92m",
                accessToken: "vSv_cFu_1tU3e5lq450_kXO5Oyz9R9Tta3urwK9ttS0",
                environment: "dev",
            };
        case "development2":
            return {
                space: "0aw1jhk0o92m",
                accessToken: "vSv_cFu_1tU3e5lq450_kXO5Oyz9R9Tta3urwK9ttS0",
                environment: "dev2",
            };
        case "staging":
            return {
                space: "0aw1jhk0o92m",
                accessToken: "vSv_cFu_1tU3e5lq450_kXO5Oyz9R9Tta3urwK9ttS0",
                environment: "dev2",
            };

        case "production":
            return {
                space: "0aw1jhk0o92m",
                accessToken: "vSv_cFu_1tU3e5lq450_kXO5Oyz9R9Tta3urwK9ttS0",
                environment: "production",
            };
        default:
            return {
                space: "0aw1jhk0o92m",
                accessToken: "vSv_cFu_1tU3e5lq450_kXO5Oyz9R9Tta3urwK9ttS0",
                environment: "production",
            };
    }
};

export const getRandomProfile = () => {
    return `/avatars/profile_${Math.floor(Math.random() * 4)}.png`;
};

export const getPhotoID = (profile) => {
    if (profile?.name?.includes("RPGMatch")) {
        return `/rpgmatch_avatar400x400.png`;
    }

    if (profile?.profileURL?.[0] && profile?.profileURL?.[0] !== AVATAR_REMOVED_PLACEHOLDER) {
        // Added Date.now() to avoid image cache
        return profile.profileURL[0] + "?" + Date.now();
    }

    if (profile?.avatar_id) {
        return `${awsFileURL()}/images/avatars/profile_${profile.avatar_id}.png`;
    }

    if (profile?.name) {
        let profileName = profile?.name?.length % 4;
        if (profile && profile?.name && Array.isArray(profile?.name)) {
            profileName = byAlphaAvatar(profile?.name?.join("")?.length, profile?.gender);
        }
        return `/avatars/profile_${profileName}.png`;
    }

    return `/avatars/deleted.png`;
};

// Remove Profile Image from S3
export async function removeProfileImages({ onSuccess, onError, onFinally } = {}) {
    return api
        .delete(endpoints.profileImageUpload)
        .then((response) => onSuccess?.(response))
        .catch((err) => onError?.(err))
        .finally(() => onFinally?.());
}

function byAlphaAvatar(nameLength, gender) {
    const male = [0, 3, 5, 6, 7, 8, 11, 12];
    const female = [2, 4];
    const other = [1];

    const avatarIndex = nameLength % 2;

    if (gender) {
        if (gender[0] === "male") {
            return male[avatarIndex];
        } else if (gender[0] === "female") {
            return female[avatarIndex];
        }
    }
    return other[0];
}

export function deriveProfiles(data, allProfiles, ignoredProfile) {
    let profiles = data.map((item) => {
        let playerType = checkPlayerType(item.questionnaireTransitionDM);

        return {
            ...item,
            isGM: playerType === "GM" || playerType === "Player + GM",
            profileImage: getPhotoID(item),
            isFavorited: false,
        };
    });

    if (ignoredProfile) {
        profiles = profiles.filter((profile) => profile.id !== ignoredProfile);
    }

    const tempState = {
        ...allProfiles,
        profiles: uniqBy([...allProfiles.profiles, ...profiles], "id"),
        originalList: profiles,
        playerList: profiles.filter((item) => !item.isGM),
        gmList: profiles.filter((item) => item.isGM),
    };
    return tempState;
}

export const formatTime = (date, timezone) => {
    return getTimeFormatter(timezone).format(date).toLowerCase();
};

export const formatDate = (date, timezone) => {
    return getDateFormatter(timezone).format(date);
};

const getDateFormatter = (timezone) => {
    return new Intl.DateTimeFormat("en-US", {
        timezone,
        month: "short",
        day: "2-digit",
    });
};

const getTimeFormatter = (timezone) => {
    return new Intl.DateTimeFormat("en-US", {
        timezone,
        hourCycle: "h11",
        hour: "2-digit",
        minute: "2-digit",
    });
};

/**
 * Remove falsy values from object
 */
export const clearFalsyFromObject = (val) => {
    const data = Array.isArray(val) ? val.filter(Boolean) : val;
    if (Object.keys(data).length <= 0) {
        return undefined;
    }
    return Object.keys(data).reduce(
        (acc, key) => {
            const value = data[key];
            if (Boolean(value) || value !== "") acc[key] = value;
            return acc;
        },
        Array.isArray(val) ? [] : {},
    );
};

export const clearFalsyFromObject2 = (val = {}) => {
    try {
        const data = Array.isArray(val) ? val.filter(Boolean) : val;
        if (Object.keys(data).length <= 0) {
            return undefined;
        }

        return Object.keys(data).reduce(
            (acc, key) => {
                const value = data[key];

                if (value !== "" && value && value?.length > 0) {
                    acc[key] = value;
                }

                if (typeof value === "boolean" && value) {
                    acc[key] = value;
                }

                return acc;
            },
            Array.isArray(val) ? [] : {},
        );
    } catch (error) {
        return {};
    }
};

export function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}

export const generateDerivedUsers = (response, allProfiles, privateProfileId) => {
    const favorites = response.data.results.map((res) => {
        const find = allProfiles?.profiles?.find((item) => {
            return item.id?.includes(res.id?.join(""));
        });

        if (find) {
            return find;
        }

        return res;
    });

    const derivedProfiles = deriveProfiles(favorites, allProfiles, privateProfileId);
    return derivedProfiles;
};

export function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
export function filterSearchParams(filters) {
    Object.keys(filters).forEach((type) => {
        const filterExists = typesOfFilter.find((x) => x === type);

        if (!filterExists) {
            delete filters[type];
        }
    });

    return filters;
}

export const convertArrayToObject = (array) => {
    return array.map((data) => {
        const transformedData = {};
        for (const key in data) {
            if (Array.isArray(data[key]) && data[key].length === 1) {
                transformedData[key] = data[key][0]; // Convert single-item arrays to single values
            } else {
                transformedData[key] = data[key]; // Keep non-array values as they are
            }
        }
        return transformedData;
    });
};

const formatGamesPlayed = (games) => {
    if (!games[0]) {
        return "-";
    }

    const firstGame = games?.[0];

    if (games.length === 1) {
        return firstGame;
    }

    const otherGameCount = games?.length - 1;
    const otherGameString = ` other game${otherGameCount > 1 ? "s" : ""}`;

    return firstGame + " + " + String(otherGameCount) + otherGameString;
};

export const completeUserData = (user) => {
    const userIsGM = isOnlyGameMaster(user) || isBoth(user);
    const userIsPlayer = isOnlyPlayer(user) || isBoth(user);
    const userIsProfessionalGM = isProfessionalGameMaster(user);

    const { city, state, country } = user;
    const formattedLocation = [city, state, country].filter((item) => item?.[0]).join(", ");

    const formattedTimeInfo = getUserCurrentTimeInfo(user["timezone"]);

    const formattedGamesPlayed = formatGamesPlayed(formatStateToCheckComplete(user));
    return {
        ...user,
        isGM: userIsGM,
        isPlayer: userIsPlayer,
        isProfessionalGM: userIsProfessionalGM,
        formattedLocation: formattedLocation,
        formattedTimeInfo,
        formattedGamesPlayed: formattedGamesPlayed,
    };
};

export const formatStateToCheckComplete = (state) => {
    const newObj = { ...state };
    const gamesPlayedNames = newObj?.gamesPlayed
        ? newObj.gamesPlayed.map((game) => {
              return game?.name || game;
          })
        : [];

    return gamesPlayedNames;
};

export const completeUsersData = (userList = []) => {
    return userList.map((item) => completeUserData(item));
};

export const formatLocation = (values, toString = false) => {
    if (toString) {
        const location = [];

        if (values?.city?.toString()) location.push(values?.city.toString());
        if (values?.state?.toString()) location.push(values?.state.toString());
        if (values?.country?.toString()) location.push(values?.country.toString());

        if (location.length > 0) {
            return location.join(", ");
        }
        return "";
    }

    const location = {};

    if (values?.city?.toString()) location.city = values?.city.toString();
    if (values?.state?.toString()) location.state = values?.state.toString();
    if (values?.country?.toString()) location.country = values?.country.toString();

    return location || "";
};

export const generateLocationOptions = (data) => {
    const locationStrings = [];
    data?.forEach((item) => {
        if (item.country) {
            if (item.state) {
                if (item.city) {
                    locationStrings.push(JSON.stringify({ country: item.country, state: item.state, city: item.city }));
                }

                locationStrings.push(JSON.stringify({ country: item.country, state: item.state }));
            }

            locationStrings.push(JSON.stringify({ country: item.country }));
        }
    });
    return [...new Set(locationStrings)].map((location) => JSON.parse(location)) || [];
};

/**
 * Generates a profile image based on the given gender.
 *
 * @param {object} options - The options for generating the profile image.
 * @param {string} options.gender - The gender of the profile image. Can be "male", "female", or any other value.
 * @return {number} The index of the selected image from the corresponding gender array.
 */
export const generateProfileImage = ({ gender }) => {
    const maleImages = [0, 3, 5, 6, 7, 11, 12, 8];
    const femaleImages = [2, 4, 10, 13];
    const ambiguous = [1, 9];

    let imageIndex;

    if (gender === "male") {
        imageIndex = maleImages;
    } else if (gender === "female") {
        imageIndex = femaleImages;
    } else {
        imageIndex = ambiguous;
    }

    const randomIndex = Math.floor(Math.random() * imageIndex.length);

    return imageIndex[randomIndex];
};

/*
 * Check if any of the arrays passed as arguments are fulfilled.
 * Checks if all arrays passed as arguments are fulfilled (have at least one element).
 *
 * @param {Array} arrays - The arrays to be checked.
 * @return {boolean} Returns true if all arrays are fulfilled, false otherwise.
 */
export function isFilled() {
    const arrays = Array.prototype.slice.call(arguments);

    for (let i = 0; i < arrays.length; i++) {
        if (arrays[i] === undefined || arrays[i].length < 1) {
            return false;
        }
    }

    return true;
}

/**
 * Returns the first truthy value in a list of properties.
 *
 * @param {...any} properties - The list of properties to check.
 * @return {any} The first truthy value found, or null if none are found.
 */
export function ComponentSwitch() {
    const properties = Array.prototype.slice.call(arguments);

    for (let i = 0; i < properties.length; i += 2) {
        if (properties[i] === true) {
            return properties[i + 1];
        }
    }

    if (properties.length % 2 !== 0) {
        return properties[properties.length - 1];
    }

    return null;
}

/**
 * Handles saving required values.
 *
 * @param {Object} param - An object containing the following properties:
 *   - {string} key - The key for the value to be saved.
 *   - {any} item - The value to be saved.
 *   - {function} setTextValue - A function to set the text value.
 *   - {function} setIsLoading - A function to set the loading state.
 *   - {function} handleEditProfile - A function to handle editing the profile.
 * @return {Promise<void>} A promise that resolves when the required values are saved.
 */
export const handleSaveRequiredValues = async ({ key, item, setTextValue, setIsLoading, handleEditProfile }) => {
    setTextValue(item);

    setIsLoading(true);
    await handleEditProfile({ [key]: item });
    //setIsLoading(false);
};

export function locationFriendlyAddr(address) {
    return address
        .split("/")
        .filter((location) => location)
        .map((location) => titleCase(location))
        .map((location) => decodeURI(location))
        .reverse()
        .join(", ");
}
/**
 * Normlizes the game name to better look in the URL.
 *
 * @param {*} game
 * @returns
 */
export const gameNameToURL = (game) => {
    const CHR = "-";
    let _game = game
        .toLowerCase()
        .replaceAll("&", "and")
        .replace(/[^\w\s]/gi, CHR)
        .replaceAll(" ", CHR);

    if (_game.startsWith(CHR)) {
        _game = _game.slice(1);
    }

    if (_game.endsWith(CHR)) {
        _game = _game.slice(0, -1);
    }

    return _game;
};

export const gameUrlToName = (url) => {
    const CHR = "-";

    let _gameName = url.toLowerCase().replaceAll(CHR, " ");

    if (_gameName.startsWith(CHR)) {
        _gameName = _gameName.slice(1);
    }

    if (_gameName.endsWith(CHR)) {
        _gameName = _gameName.slice(0, -1);
    }

    return _gameName;
};

export const matchGameName = (gameName, items) => {
    const arrayItens = items.map((item) => item.fields);
    const itemNameMatch = arrayItens.filter((item) => item.name.toLowerCase() == gameName);

    return itemNameMatch;
};

export const saveRedirect = async () => {
    const isLoggedIn = await checkLoggedIn();
    if (!isLoggedIn) {
        storage.sessionStorage.redirect.setPath();
    }
};

export function getCurrentGMT() {
    // Get the GMT offset
    dayjs.extend(utc);
    const gmtOffset = dayjs().utcOffset();
    const gmtHours = Math.floor(gmtOffset / 60);
    const gmtMinutes = gmtOffset % 60;
    const gmt = `${gmtHours >= 0 ? "+" : "-"}${Math.abs(gmtHours).toString().padStart(2, "0")}:${gmtMinutes
        .toString()
        .padStart(2, "0")}`;

    return gmt;
}

/**
 * Checks if the platform name is "A table (not a VTT)".
 *
 * @param {Object | null} platform - The platform object to check.
 * @return {boolean} Returns true if the platform name is "A table (not a VTT)", otherwise false.
 */
export function platformIsATable(platform) {
    if (!platform) return false;
    return platform.name === "A table (not a VTT)";
}

export function isEmpty(value) {
    if (typeof value === "string") {
        return value === "";
    } else if (Array.isArray(value)) {
        return value.length === 0;
    }
    return false;
}

export function getWeekdayAbbreviation(weekday) {
    switch (weekday) {
        case "Monday":
            return "MON";
        case "Tuesday":
            return "TUE";
        case "Wednesday":
            return "WED";
        case "Thursday":
            return "THU";
        case "Friday":
            return "FRI";
        case "Saturday":
            return "SAT";
        case "Sunday":
            return "SUN";
        default:
            return weekday;
    }
}

/**
 * Receives a list of values in [condition, true value, false value] format
 * and returns the true value if the condition is true, otherwise the false value.
 *
 * All values will be concatenated in a final string separated by a space.
 *
 * Edge cases:
 *
 * 1. If only the condition and true value is provided, in case the condition is false then
 * return nothing.
 *
 * 2. If only one value is provided, that value will be returned in the final string.
 *
 * @param {...any} args - The list of values in [condition, true value, false value] format.
 * @returns {string} - The concatenated string of the true values if the condition is true, otherwise the false value.
 */
export function conditionalClassName(...args) {
    return args
        .map((arg) => {
            const [condition, trueValue, falseValue] = arg;

            // In case no condition is provided return the true value
            if (!trueValue && !falseValue) return condition;

            if (condition) return trueValue;
            return falseValue;
        })
        .filter(Boolean)
        .join(" ");
}

export async function getImageUrl(img) {
    const extensions = [".png", ".jpg", ".jpeg"];
    const path = img.split(".").slice(0, -1).join(".");

    for (const extension of extensions) {
        const url = path + extension;

        try {
            // fetch the image URL with fetch
            const response = await fetch(url);

            if (response.status === 200) {
                return url;
            }
        } catch (error) {
            continue;
        }
    }

    return null;
}

/**
 * Generate Group name based in business rule
 * @param {Partial<import("models/group/GroupModel").GroupType>} group
 * @param {Array<import("models/cms/fileds").GameType>} games
 * @returns
 */
export function getGroupName(group, games) {
    const game = games.find((game) => game?.id === group?.game);

    return `${group?.Creator}’s Group ${Boolean(game) ? `| ${game?.name}` : ""}`;
}

/**
 * Check if group is full
 * @param {Partial<import("models/group/GroupModel").GroupType>} group
 * @returns {boolean}
 */
export function groupIsFull(group) {
    return (group.members?.length || 0) >= (group.group_size || 0);
}
