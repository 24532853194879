import { api } from "./api";
import { endpoints } from "./endpoints";
import axios from "axios";
import { checkLoggedIn, environmentApiUrl, randomIntFromInterval } from "../utils/functions";

// Returns a list of all profiles
async function getProfiles(params = "") {
    try {
        const response = await api.get(endpoints.profilePaginate + "?resultsPerPage=20&pageNumber=1" + params);
        const data = response.data.results.map((item) => {
            const state = {
                ...item.v,
                match_score: randomIntFromInterval(30, 40),
            };

            if (item?.m?.percentage) {
                state.match_score = item?.m?.percentage;
            }

            if (item?.v?.answered_matching_questions && item?.v?.answered_matching_questions?.length > 0) {
                state.answered_matching_questions = item?.v?.answered_matching_questions[0];
            }
            return state;
        });

        return { data: { results: data } };
    } catch {}
}

// Returns a list of all Blocked profiles
async function getBlockedProfiles() {
    try {
        return api.get(endpoints.blockedList);
    } catch {}
}

// getFilterResults function is a request to the server to get the filter results for the profile
// searchParams is the query params that will be added to the url
async function getFilterResults(searchParams) {
    try {
        return api.get(`${endpoints.profilePaginate}?${searchParams}`);
    } catch {}
}

export async function getProfilePaginateResults(searchParams) {
    try {
        return api.get(`${endpoints.profilePaginate}?resultsPerPage=20&pageNumber=1`);
    } catch {}
}

// This function calls an API to get results based on a given filter value
// It then formats the results to be used in the AutoComplete component
async function filterUsersAutocomplete(value, setValue) {
    if (value !== "") {
        const response = await getFilterResults(`name=${value}`);

        if (response) {
            const newUsers = [];
            response.data.results.forEach((element, i) => {
                newUsers.push({ id: i, label: element.v.name });
            });

            setValue(newUsers);
        }
        return;
    }

    setValue([]);
}

async function getProfile(id) {
    try {
        return await api.get(`${endpoints.profile}${id}`);
    } catch {}
}

async function getProfileByVanityURLAsync(vanityUrl) {
    try {
        const isLoggedIn = await checkLoggedIn();

        const url = isLoggedIn
            ? `${endpoints.profileByUrl}${vanityUrl}`
            : `${endpoints.publicProfileByUrl}${vanityUrl}`;

        return await api.get(url);
    } catch {}
}

async function getPersonalProfile() {
    try {
        const response = await api.get(endpoints.personalProfile);
        return response.data;
    } catch {}
}

async function blockUser(data) {
    try {
        const response = await api.put(endpoints.blockUser, data);
        return response;
    } catch {
        throw Error();
    }
}

/**
 * This function is used to put a user as a favorite in the database
 * @param {Object} data - The data containing the user's id and the favorite user's id
 * @returns {Object} response - The response from the API
 */
async function putFavorite(data) {
    try {
        const response = await api.put(endpoints.putFavorite, data);
        return response;
    } catch {}
}

// This function calls the API to get the list of favorites
// The function returns a list of favorites, with the favorited property set to true
// The function also handles errors and logs them to the console
async function getFavorites() {
    try {
        const response = await api.get(endpoints.favoritesList);
        const favs = response.data.map((item) => {
            return {
                ...item,
                favorited: true,
            };
        });
        return favs;
    } catch {}
}

/**
 * Create profile on cognito using email
 * @param {string} email
 * @returns
 */
async function createProfile(email) {
    try {
        const request = axios.CancelToken.source();
        return axios.post(
            environmentApiUrl() + endpoints.profileCreate,
            { email: email.toLowerCase() },
            {
                headers: {
                    "Content-Type": "application/json",
                },
                cancelToken: request.token,
            },
        );
    } catch {}
}

async function editProfile(data, { callback, errorCallback, finallyCallback } = {}) {
    return await api
        .put(endpoints.profileEdit, data)
        .then((response) => callback?.(response))
        .catch((err) => {
            errorCallback?.(err);
        })
        .finally(() => finallyCallback?.());
}

async function putValuesTwitter(data) {
    try {
        return await api.put(endpoints.twitterUpdate, data);
    } catch (error) {
        const reponse = {
            text: error.request.responseText, //This is the way to get error response
            status: 400,
        };
        return reponse;
    }
}

async function putMatchProjection(data) {
    try {
        return await api.put(endpoints.putMatchProjection, data);
    } catch {}
}

async function postBadge(data) {
    return await api.post(endpoints.badge, data);
}

async function closeAccount() {
    return api.delete(endpoints.profileWithoutId);
}

async function getTimezone() {
    return await api.get(endpoints.profileTimezone);
}

/**
 * Search for profiles to be invited
 * @param {string} search
 * @param {AbortSignal | undefined} signal
 * @returns {Promise<import("models/ProfileModels").ProfileSearchResponse>}
 */
async function searchProfile(search, signal) {
    // Creating a controller to abort the request when needed
    const controller = new AbortController();
    const { signal: abortSignal } = controller;

    if (signal) signal.addEventListener("abort", () => controller.abort());

    return (await api.get(endpoints.profileSearch, { params: { search } }, { signal: abortSignal })).data;
}

const profileProvider = {
    getProfiles,
    getFilterResults,
    filterUsersAutocomplete,
    getProfile,
    getProfileByVanityURLAsync,
    getPersonalProfile,
    getProfilePaginateResults,
    getBlockedProfiles,
    blockUser,
    editProfile,
    putFavorite,
    getFavorites,
    createProfile,
    putValuesTwitter,
    putMatchProjection,
    postBadge,
    closeAccount,
    getTimezone,
    searchProfile,
};

export { profileProvider };
