import { api } from "services/api";
import { endpoints } from "services/endpoints";
import { Auth } from "aws-amplify";

const useChangeEmail = () => {
    /**
     * Change user email attribute (Amplify/Cognito)
     * @param {string} newEmail
     * @returns {Promise<any>}
     */
    const change = async (newEmail) =>
        await api.post(endpoints.changeEmail, {
            email: newEmail?.toLowerCase?.(),
        });
    // Update database (Neptune)
    const update = async () => await api.put(endpoints.changeEmail, {});
    // Resend user attribute confirmation code (Amplify/Cognito)
    const resend = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return await Auth.verifyUserAttribute(user, "email");
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return {
        change,
        update,
        resend,
    };
};

export default useChangeEmail;
