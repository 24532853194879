import React, { Dispatch } from "react";
import { View } from "@aws-amplify/ui-react";
import { Chip } from "@mui/material";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { awsFileURL, isFilled } from "utils/functions";
import PlayerVerifiedIcon from "components/Profile/components/PlayerVerifiedIcon";
import "./GroupsFilterChips.scss";
import { GroupFilters } from "hooks/useGroupsList";
import { CMSContent } from "models/cms/fileds";
import { SetStateAction } from "jotai";

interface GroupsFilterChipsProps {
    currentFilters: GroupFilters;
    filterActive: boolean;
    clearFilter: ({
        preserveFilters,
        removeFilters,
    }: {
        preserveFilters: never[];
        removeFilters: never[];
    }) => Promise<void>;
    filterFields: CMSContent;
    isLoggedIn: boolean;
    setLoginPopup: Dispatch<SetStateAction<boolean>>;
}

const filterValues = [
    { key: "city", name: "City" },
    { key: "country", name: "Country" },
    { key: "game", name: "Game" },
    { key: "game_hosting_type", name: "Game Hosting Type" },
    { key: "languages", name: "Languages" },
    { key: "location", name: "Location" },
    { key: "platform", name: "Platform" },
    { key: "safetyTools", name: "Safety Tools" },
    { key: "state", name: "State" },
];

const FiltersToShow = ({ makeObject, getLabel, currentFilters, onDelete }: any) => {
    return filterValues.map((filter: { key: string; name: any }) => {
        const filterItem = currentFilters[filter.key];

        if (!filterItem) return null;
        const currentFilter = !Array.isArray(filterItem)
            ? filterItem
            : filterItem.map((item) => makeObject(item, filter.key));
        if (!Boolean(currentFilter)) return null; // Case it has no filter
        if (!Array.isArray(currentFilter)) {
            const label = getLabel(filter.name, currentFilter),
                key = `groups-filter-chip-${filter.name}`;

            return (
                <Chip
                    key={key}
                    label={label}
                    variant="outlined"
                    data-testid="groups-filter-chip"
                    onDelete={() => onDelete([filter.key])}
                />
            );
        }
        const Badges = (value: { value: { image: any; img: any; name: any } }) => {
            if (filter.key === "badgesOptions") {
                const img = value?.value?.image || value?.value?.img;
                const name = value?.value?.name;

                return (
                    <img
                        className="MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
                        height="20px"
                        width={"20px"}
                        src={`${awsFileURL()}/badges/${img}`}
                        alt={`${name} Badge`}
                    />
                );
            }

            return null;
        };

        // Default behavior
        const isObject = typeof currentFilter[0] === "object";
        return currentFilter.map((value, index) => {
            if (index < 3) {
                const key = `filter-chip-${filter.name}-${index}`,
                    label = getLabel(filter.name, isObject ? value?.languageName || value.name : value);
                return (
                    <Chip
                        key={key}
                        data-testid="filter-chip"
                        label={label}
                        icon={<Badges value={value} />}
                        variant="outlined"
                        onDelete={() => {
                            if (["game", "platform", "languages"].includes(filter.key)) {
                                onDelete([{ key: filter.key, value: isObject ? value.id : value }]);
                                return;
                            }

                            onDelete([filter.key]);
                        }}
                    />
                );
            }

            if (index === 3) {
                return <div>{`+${currentFilter.length - 3}`}</div>;
            }

            return <></>;
        });
    });
};

export const GroupsFilterChips = ({
    currentFilters = {},
    filterActive,
    clearFilter,
    filterFields,
    isLoggedIn,
    setLoginPopup,
}: GroupsFilterChipsProps) => {
    if (!filterActive) {
        return null;
    }

    const { vtt, games, languages } = filterFields;

    const makeObject = (currentFilter: string | undefined, filterKey: string) => {
        if (currentFilter === undefined) return;
        switch (filterKey) {
            case "platform":
                return vtt?.find((vtt: { id: number }) => vtt?.id === parseInt(currentFilter));
            case "game":
                return games?.find((game: { id: number }) => game?.id === parseInt(currentFilter));
            case "languages":
                return languages?.find((language: { id: number }) => language?.id === parseInt(currentFilter));
            default:
                return currentFilter;
        }
    };

    const getLabel = (title: string, value: any) => {
        let filterValue = value?.name || value?.languageName || value;
        switch (title) {
            case "Availability":
                filterValue = "Matches my availability";
                break;
            case "Type of Player":
                filterValue = value === "DM" ? "GM" : value;
                break;
            case "Accounts":
                filterValue = (
                    <View className="verified-chip">
                        <PlayerVerifiedIcon />
                        <span>Verified</span>
                    </View>
                );
                break;
            case "Languages":
                const find = filterFields.languages.find((item: { id: number }) => item.id === parseInt(value, 10));
                filterValue = find?.languageName || value;
                break;
            default:
                break;
        }

        return (
            <div className="chip-label" data-testid="chip-label">
                <span>{`${title}: `}</span>
                <span className="chip-value">{filterValue}</span>
            </div>
        );
    };

    const onDelete = async (filters: any) => {
        if (isLoggedIn) {
            clearFilter({
                preserveFilters: [],
                removeFilters: filters,
            });
        } else {
            setLoginPopup(true);
        }
    };

    return (
        <ShouldRender returnNull condition={isFilled(vtt, games, languages)}>
            <View className="groups-filter-chips" data-testid="groups-filter-chips">
                <FiltersToShow
                    makeObject={makeObject}
                    getLabel={getLabel}
                    currentFilters={currentFilters}
                    onDelete={onDelete}
                />
            </View>
        </ShouldRender>
    );
};
