import "./MessagesBlocker.scss";
import React, { PropsWithChildren, useState } from "react";
import {useSnackbarContext} from "@context/SnackbarContext";
import LoadingBtn from "@components/LoadingBtn/LoadingBtn";
import { useUser } from "@pages/Inbox/hooks/useUser";

export const MessagesBlocker = ({ children }: PropsWithChildren) => {
    const { showSnackbar } = useSnackbarContext();
    const { unblockUser, blockedUser } = useUser();

    const [loadingUnblock, setLoadingUnblock] = useState(false);

    const onClickUnblock = async () => {
        const anchor = { vertical: "top", horizontal: "left" };
        setLoadingUnblock(true);

        try {
            await unblockUser({});
            showSnackbar({
                title: "Success",
                description: "You unblocked this user.",
                severity: "success",
                anchor,
            });
        } catch {
            showSnackbar({ anchor });
        } finally {
            setLoadingUnblock(false);
        }
    };

    return (
        <>
            {blockedUser && (
                <div className="block-layer">
                    <p>You blocked this user.</p>
                    <p>They will not be able to send you messages.</p>
                    <LoadingBtn
                        className="message-unblock-btn"
                        handleOnClick={onClickUnblock}
                        label="UNBLOCK"
                        loading={loadingUnblock}
                        variant="outlined"
                    />
                </div>
            )}
            {children}
        </>
    );
};
