import React from "react";

import { Box } from "@mui/material";

type GroupsListItemProps = React.PropsWithChildren & {
    ref?: React.RefObject<HTMLDivElement>;
};
export const GroupsListList = ({ ref, children }: GroupsListItemProps) => {
    return (
        <Box ref={ref} className="groups-items-list">
            {children}
        </Box>
    );
};
