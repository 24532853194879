import React, { useContext, useEffect, useState } from "react";
import GMIcon from "components/Profile/GMIcon";
import PlayerIcon from "components/Profile/PlayerIcon";
import { View } from "@aws-amplify/ui-react";
import { eventBadges } from "utils/constants";
import VTTIcon from "components/Profile/VttIcon";
import { get } from "lodash";
import { Tooltip } from "@mui/material";
import { awsFileURL } from "utils/functions";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import StartPlayingIcon from "components/Profile/components/StartPlayingIcon";
import { useLocation } from "react-router-dom";

export const ShowPlayerBadges = ({ item, vttList, loading, VTTOptions }) => {
    const [badges, setBadges] = useState([]);
    const { filterFields } =  useContext(AsyncFilterFieldsContext);
    const generalBadges = filterFields?.badgesOptions || [];
    const getVttImage = (vttName) => {
        const vttObject = (filterFields?.vtt || VTTOptions)?.find((vttOption) => vttOption.name === vttName);
        const vttImage = get(vttObject, "img");
        return vttImage;
    };

    const location = useLocation();

    useEffect(() => {
        setBadges([]);

        if (item.isGM) {
            setBadges((badges) => [...badges, <GMIcon className="icon" key={"GM"} />]);
        }
        if (item.isPlayer) {
            setBadges((badges) => [...badges, <PlayerIcon className="icon" key={"Player"} />]);
        }
        if (item.isProfessionalGM) {
            setBadges((badges) => [...badges, <StartPlayingIcon className="icon" key={"PlayerPro"} />]);
        }
        if (vttList) {
            const vttBadges = [];
            vttList.forEach((vtt) => {
                const vttImage = getVttImage(vtt);
                if (vttImage) {
                    vttBadges.push(
                        <VTTIcon
                            tooltipTitle={`This user has an exclusive ${vtt} badge for having their account linked to their RPGMatch profile.`}
                            vttName={vtt}
                            vttImage={vttImage}
                            key={`${vtt} badge`}
                        />,
                    );
                }
            });
            setBadges((badges) => [...badges, ...vttBadges]);
        }
        if (item?.badges) {
            const userBadges = [];

            item.badges.forEach((badge) => {
                const eventBadge = eventBadges.find((x) => x.id === badge);
                const generalBadge = generalBadges.find((x) => x.id === badge);
                const currentBadge = eventBadge || generalBadge;

                if (currentBadge) {
                    const tooltip = eventBadge
                        ? "This user has an exclusive Convention Attendee badge!"
                        : `This user has an exclusive ${currentBadge.name} badge for having their account linked to their RPGMatch profile.`;

                    userBadges.push(
                        <Tooltip key={`${currentBadge.id}-badge`} placement="top-start" title={tooltip}>
                            <img
                                src={`${awsFileURL()}/badges/${currentBadge?.image || currentBadge?.img}`}
                                alt={`${currentBadge.name} Badge`}
                            />
                        </Tooltip>,
                    );
                }
            });

            if (userBadges.length > 0) {
                setBadges((badges) => [...badges, ...userBadges]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    if (loading) return null;

    return (
        <View className="user-icons">
            {(location.pathname === "/user-list" || location.pathname === "/starred") && badges.length > 4 ? (
                <>
                    {badges.slice(0, 5).map((item, i) => {
                        return <React.Fragment key={item.key}>{item}</React.Fragment>;
                    })}
                    <p>{badges.length - 5 > 0 ? `+ ${badges.length - 5}` : ""}</p>
                </>
            ) : (
                badges.map((item, i) => {
                    return <React.Fragment key={item.key}>{item}</React.Fragment>;
                })
            )}
        </View>
    );
};
